import specialCharacters from "./passwordSpecialCharacters";
import errors from "./validationErrors";

/* eslint-disable */
const emailTest = /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
// const nameTest = /^[A-Za-z\u{00C0}-\u{00FF}][A-Za-z\u{00C0}-\u{00FF}'-]+([ A-Za-z\u{00C0}-\u{00FF}][A-Za-z\u{00C0}-\u{00FF}'-]+)*$/u
const nameTest = /[^ \p{L} -]/gu
/* eslint-enable */

const emailIsValid = (email) => emailTest.test(email);
const nameIsValid = (name) => !nameTest.test(name);
const reducer = (pwd) => (add, exp) => (pwd.match(exp) ? add + 1 : add);
const pwdContainsLetterSizes = (pwd) => [/[a-z]/, /[A-Z]/].reduce(reducer(pwd), 0) === 2;
const pwdCharsNumsValid = (pwd) => [/[0-9]/, specialCharacters].reduce(reducer(pwd), 0) >= 1;
export const passwordIsValid = (pwd) =>
  pwd && pwd.length >= 8 && pwd.length <= 128 && pwdContainsLetterSizes(pwd) && pwdCharsNumsValid(pwd);

export const getPasswordValidity = (pwd) => [
  pwdContainsLetterSizes(pwd),
  pwdCharsNumsValid(pwd),
  pwd.length >= 8 && pwd.length <= 128,
];

export const getEmailIsValid = emailIsValid;
export const validateEmail = async (email) => {
  if (email === "" || !emailIsValid(email)) throw [errors.emailInvalid];
  return true;
};

export const validateLoginForm = async (username, password) => {
  const validationErrors = [];

  if (username === "" || password === "") validationErrors.push(errors.invalidRequest);
  if (passwordIsValid(password) !== true) validationErrors.push(errors.passwordStrength);
  if (validationErrors.length > 0) throw validationErrors;
  return true;
};

export const getFirstNameValidationError = (val) =>
  val === "" ? errors.missingFirstName : !nameIsValid(val) ? errors.invalidFirstName : null;
export const getLastNameValidationError = (val) =>
  val === "" ? errors.missingLastName : !nameIsValid(val) ? errors.invalidLastName : null;
export const getEmailValidationError = (val) => (val === "" || !emailIsValid(val) ? errors.emailInvalid : null);
export const getPasswordValidationError = (val) => {
  if (val.length < 8) return errors.passwordLength;
  return passwordIsValid(val) !== true ? errors.registerPasswordInvalid : null;
};
export const getConsentValidationError = (val) => (!val ? errors.consentRequired : null);

export const validateRegisterForm = async (formData) => {
  const validationErrors = [
    getFirstNameValidationError(formData.firstName),
    getLastNameValidationError(formData.lastName),
    getEmailValidationError(formData.email),
    getPasswordValidationError(formData.password),
    getConsentValidationError(formData.consent),
  ].filter((val) => val != null);
  if (validationErrors.length > 0) throw validationErrors;
  return formData;
};
